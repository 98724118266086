* {
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#modal-root {
  /* z-index: 999999; */
  position: relative;
}
 
.demo-image-wrapper:hover .demo-btn {
  opacity: 1;
  transform: translate(-50%, 0);
}

.error {
  color: red;
}

.thumbParentVideo {
  position: relative;
}

.thumbParentVideo Button {
  position: absolute;
  top: 0;
  right: 0;
  height: 20px;
  width: 20px;
  background: red;
  z-index: 1;
}
.thumbParent {
  position: relative;
}

.editThumbParent {
  position: relative;
}

.editThumbParent Button {
  position: absolute;
  top: 0;
  right: 0;
  height: 20px;
  width: 20px;
  background: red;
  /* z-index: 1; */
}


.thumbParent .thumbParentButton{
  position: absolute;
  top:0;
  right: 5px;
}
.main__thumb{
  /* border: 1px solid ; */
  border-bottom-style:dashed;
  border-right-style:dashed;
  border-left-style:dashed;
  border-top-style:dashed;
  border-bottom-color:rgb(203, 203, 203);
  border-right-color:rgb(203, 203, 203);
  border-left-color:rgb(203, 203, 203);
  border-top-color:rgb(203, 203, 203);
  border-bottom-width:2px;
  border-right-width:2px;
  border-left-width:2px;
  border-top-width:2px;
  margin:10px
}

.thumb__footer__button{
  display: flex;
  align-items: center;
  bottom: 0;
  padding: 5px 8px;
  justify-content: space-between;
}

.carousel .slide img {
  width: 500px;
  height: 200px;
  vertical-align: top;
  object-fit: contain;
}

.carousel .slide {
  background: white !important;
}
.page-header {
  margin-bottom: 15px;
  padding: 15px !important;
}
/* .b9 {
  padding: 8px 91px!important;
} */
/* .du {
  padding: 15px 26px!important;
} */
.col-padding {
  padding: 0 15px !important;
}

striped-table {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
  border: 1px solid #cecece;
}

.table-data {
  text-align: left;
  padding: 16px;
  border: 1px solid #b4b4b4;
}

tr:nth-child(even) {
  background-color: #f2f2f2;
}

.table-button-grid {
  width: 180px !important;

  @media only screen and (max-width: 767px) : {
    width: 100px;
  }
}
.centerItem {
  display: flex;
  justify-content: center;
  /* @media only screen and (max-width: 767px): {
    display: flex;
    justify-content: center;
} */
}
.ck .ck-editor__main {
  padding: 0 !important;
}

.select-form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.container-fluid {
  padding-right: 20px !important;
  padding-left: 20px !important;
}
.tree-menu-item {
  background: #f7f7f7;
}
/* .ai.aq.fh.fi.fj {
  overflow: scroll;
  height: 800px;
} */

.address__card {
  background-color: white;
  padding: 10px;
  border-radius: 2px;
  margin-top: 20px;
}
.address__card h3 {
  margin-bottom: 0px!important;
}
.address__card--info {
  display: flex;
  justify-content: space-between;
  padding: 0 20px; 
}
.address__card--bg{
  background: #d6c4e4;
}
.page__header--icon {
  font-size: 17px !important;
}

.centerItem button {
  border-radius: 5px;
  transition: all 0.5s ease;
}

.centerItem button:hover {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.product__priority__list--container {
  width: 55%;
  margin: auto !important;
}

.product__details--name {
  padding: 15px 0;
  font-size: 15px !important;
}

.carousel .thumbs-wrapper {
  margin: 0px !important;
}

.react__video__player {
  margin: auto;
  margin-bottom: 20px;
  width: 400px;
  height: 193px;
}
.react__video__player__productDetails {
  margin: auto;
  margin-bottom: 20px;
  width: 600px;
  height: 200px;
}

/* media query */
/* sm */
@media (max-width: 640px) {
  .product__filter__mb--xs {
    margin-bottom: 10px;
  }
  .page__header__icon--width {
    width: 100%;
  }
  .depertment__serch__input {
    margin: 20px 0 !important;
  }
}

/* md */
@media (max-width: 768px) {
  .product__filter--mt {
    margin-top: 10px;
  }
  .page__header--btn {
    margin-bottom: 5px !important;
  }
  .carousel .thumbs {
    padding-inline-start: 0px !important;
  }
  .product__details--name {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
  .drawar__item--close {
    left: 73.5% !important;
  }
  .react__video__player {
    width: 100%;
  }
}

/* lg */
@media (max-width: 1024px) {
  .container-fluid {
    padding-right: 0px !important;
    padding-left: 0px !important;
  }
  .page__header--btn {
    width: 100%;
  }
  .drawar__item--close {
    left: 85% !important;
  }
}

/* xl */
@media (max-width: 1280px) {
}

/* 2xl */
@media (max-width: 1536px) {
}


/* scroil bar design  */
/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #007aff;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.App {
  font-family: sans-serif;
  text-align: center;
}


.thumbParent .crop-button {
  position: absolute;
  top: 0;
  right: 0;
  height: 30px;
  width: 40px;
  background: rgb(0, 189, 135);
  z-index: 1;
}

.main__thumb_footer_border {
  border-top-style: dashed;
  border-top-color: rgb(203, 203, 203);
  border-top-width: 2px;
  padding: 5px;
}




